module.exports = {
  env: process.env.NODE_ENV,
  releaseVersion: process.env.NEXT_PUBLIC_RELEASE_VERSION,
  apiUrl: process.env.NEXT_PUBLIC_API_URL || '/',
  noIndexEnabled: process.env.NEXT_PUBLIC_NO_INDEX == 'true',
  fbPixelId: process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID,
  fbPixelDebug: process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_DEBUG === 'true',
  tawkToPropertyId: process.env.NEXT_PUBLIC_TAWKTO_PROPERTY_ID,
  tawkToKey: process.env.NEXT_PUBLIC_TAWKTO_KEY,
  gaId: process.env.NEXT_PUBLIC_GA_ID,
  gaDebug: process.env.NEXT_PUBLIC_GA_DEBUG === 'true',
  ga4: process.env.NEXT_PUBLIC_GA4_ID,
  gtmId: process.env.NEXT_PUBLIC_GTM_ID,
  billedAnnuallyByDefault: false,
  propellerAdsTrackingId: process.env.NEXT_PUBLIC_PROPELLER_ADS_TRACKING_ID,
  adsBridgePostbackUrl: process.env.NEXT_PUBLIC_ADS_BRIDGE_S2S_POSTBACK_URL,
  propellerAdsPostbackUrl: process.env.NEXT_PUBLIC_PROPELLER_ADS_S2S_POSTBACK_URL,
  registrationPayout: process.env.NEXT_PUBLIC_AFFILIATE_REGISTRATION_PAYOUT || 0.0,
  salePayout: process.env.NEXT_PUBLIC_AFFILIATE_SALE_PAYOUT || 0.0,
  welcomeBonusPercents: 36,
  blackFridayPercents: 50,
  supportEmail: 'support@trafficbooster.pro',
  apmServiceName: process.env.NEXT_PUBLIC_APM_SERVICE_NAME,
  apmUrl: process.env.NEXT_PUBLIC_APM_URL,
};
