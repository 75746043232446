import { LOAD_FEATURES, LOAD_SESSION, LOAD_USER, PUSH_NOTIFIER, REMOVE_NOTIFIER } from 'src/const/actionTypes';

const user = (
  state = {
    isLoggedIn: false,
    reference: '',
    countryCode: '',
    token: '',
    username: '',
    avatarUrl: '',
    email: '',
    roles: [],
    features: [],
    notifiers: {},
    loaded: false,
  },
  action
) => {
  switch (action.type) {
    case LOAD_SESSION:
      return {
        ...state,
        isLoggedIn: action.isLoggedIn,
        token: action.token,
        loaded: true,
      };
    case LOAD_USER:
      return {
        ...state,
        reference: action.reference,
        countryCode: action.countryCode,
        username: action.username,
        avatarUrl: action.avatarUrl,
        email: action.email,
        roles: action.roles,
      };
    case LOAD_FEATURES:
      return {
        ...state,
        features: action.features,
      };
    case PUSH_NOTIFIER:
      return {
        ...state,
        notifiers: {
          ...state.notifiers,
          [action.name]: action.notifier,
        },
      };
    case REMOVE_NOTIFIER:
      delete state.notifiers[action.name];
      return {
        ...state,
        notifiers: {
          ...state.notifiers,
        },
      };
    default:
      return state;
  }
};

export default user;
