import moment from 'moment';

import { LOAD_FEATURES, LOAD_SESSION, LOAD_USER, PUSH_NOTIFIER, REMOVE_NOTIFIER } from 'src/const/actionTypes';
import { BLACK_FRIDAY_FEATURE, GA_CHECK_REQUIRED, WELCOME_BONUS } from 'src/const/features';
import { BLACK_FRIDAY_NOTIFIER, DEMO_OFFER, GA_CHECK } from 'src/const/notifiers';

export const loadSession =
  ({ isLoggedIn, token }) =>
  (dispatch) => {
    dispatch({
      type: LOAD_SESSION,
      isLoggedIn,
      token,
    });
  };

export const loadUser =
  ({ reference, username, avatarUrl, email, roles, countryCode }) =>
  (dispatch) => {
    dispatch({
      type: LOAD_USER,
      reference,
      username,
      avatarUrl,
      email,
      roles,
      countryCode,
    });
  };

export const loadFeatures = (features) => (dispatch) => {
  const welcomeBonusFeature = features.find(({ type, active }) => type === WELCOME_BONUS && active);
  const gaCheckRequired = features.find(({ type, active }) => type === GA_CHECK_REQUIRED && active);
  const blackFridayFeature = features.find(({ type, active }) => type === BLACK_FRIDAY_FEATURE && active);

  if (blackFridayFeature) {
    dispatch(
      pushNotifier(BLACK_FRIDAY_NOTIFIER, {
        enabled: blackFridayFeature.active,
        priority: blackFridayFeature.priority,
        duration: moment.duration(moment(blackFridayFeature.expiredAt).diff(moment())),
        // duration: moment.duration(moment().add(5, 'seconds').diff(moment()))
      })
    );
  } else if (welcomeBonusFeature) {
    dispatch(
      pushNotifier(DEMO_OFFER, {
        enabled: welcomeBonusFeature.active,
        priority: welcomeBonusFeature.priority,
        duration: moment.duration(moment(welcomeBonusFeature.expiredAt).diff(moment())),
      })
    );
  }

  if (gaCheckRequired) {
    setTimeout(() => {
      dispatch(
        pushNotifier(GA_CHECK, {
          enabled: gaCheckRequired.active,
          priority: gaCheckRequired.priority,
        })
      );
    }, 5000); // delay for GA check on loading
  }

  dispatch({
    type: LOAD_FEATURES,
    features,
  });
};

export const pushNotifier =
  (name, notifier = {}) =>
  (dispatch) => {
    dispatch({
      type: PUSH_NOTIFIER,
      name,
      notifier,
    });
  };

export const removeNotifier = (name) => (dispatch) => {
  dispatch({
    type: REMOVE_NOTIFIER,
    name,
  });
};
