import Cookies from 'js-cookie';
import { COOKIE_ASSIGN_FEATURE } from '../src/const/cookies';

export default function storeTracks(router) {
  const {
    query: {
      feature = null,
      utm_source = null,
      utm_medium = null,
      utm_campaign = null,
      ref = null,
      tid = null,
      SUBID = null,
      cpid = null,
    },
  } = router;

  if (utm_source) {
    Cookies.set('utm_source', utm_source, { expires: 365 });
    Cookies.set('utm_medium', utm_medium, { expires: 365 });
    Cookies.set('utm_campaign', utm_campaign, { expires: 365 });
  }

  if (feature) {
    Cookies.set(COOKIE_ASSIGN_FEATURE, feature, { expires: 1 });
  } else {
    Cookies.remove(COOKIE_ASSIGN_FEATURE);
  }

  if (ref) {
    Cookies.set('affiliate_reference', ref, { expires: 365 });
  }

  if (tid || SUBID) {
    Cookies.set('clickId', tid || SUBID, { expires: 365 });
  }

  if (cpid) {
    Cookies.set('cpid', cpid, { expires: 365 });
  }
}
