import React, { useEffect, useRef, useState } from 'react';
import { wrapper } from 'src/store';

import {
  // env,
  // releaseVersion,
  fbPixelId,
  fbPixelDebug,
  tawkToPropertyId,
  tawkToKey,
  gaId,
  gaDebug,
  ga4,
  gtmId,
  // apmServiceName,
  // apmUrl,
} from '../config.client';

import { appWithTranslation } from 'next-i18next';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/globals.css';
import '../../styles/fa.min.css';
import '../../styles/animate.min.css';

import storeTracks from 'lib/storeTracks';
import FacebookPixel from 'src/utils/facebookPixel';
import tawkTo from 'tawkto-react';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import TagManager from 'react-gtm-module';
import { useRouter } from 'next/router';
import {
  API_MAIL_CLICK,
  GUEST_ONLY_ROUTES,
  INTERNAL_API_AUTH_OTP,
  INTERNAL_API_SESSION,
  PANEL_DASHBOARD,
  PROTECTED,
  SIGN_IN,
} from '../const/routes';
import { useDispatch } from 'react-redux';
import { loadSession } from '../actions/user';
import axios from 'axios';
import styled from 'styled-components';
import spinnerSvg from 'src/assets/img/spinner.svg';

// import graylog from 'graylog2';
// import { init as initApm } from '@elastic/apm-rum';
import * as Sentry from '@sentry/nextjs';

// Elasticsearch APM
// const apm = initApm({
//   serviceName: apmServiceName,
//   serverUrl: apmUrl,
//   serviceVersion: releaseVersion,
//   environment: env,
// });

// Axios
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    Sentry.captureException(error, {
      extra: {
        requestBody: error?.response?.config?.data,
        responseBody: error?.response?.data,
      },
    });
    // apm.captureError(error, {
    //   custom: {
    //     requestBody: error?.response?.config?.data,
    //     responseBody: error?.response?.data,
    //   },
    // });
    return Promise.reject(error);
  }
);

const LoadingScreen = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  background: url(${spinnerSvg.src}) no-repeat center center, linear-gradient(#1e1e2f, #1e1e24);
`;

const TrafficBoosterApp = ({ Component, pageProps }) => {
  const useLayout = Component.getLayout || ((page) => page);
  const router = useRouter();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const initialized = useRef(false);

  useEffect(async () => {
    if (router.isReady && !initialized.current) {
      initialized.current = true;
      const { otp = null, mid = null } = router.query;
      let isLoggedIn;
      let token;
      let loggedInByOtp = false;

      storeTracks(router);

      if (otp) {
        try {
          ({
            data: { isLoggedIn, token },
          } = await axios.post(INTERNAL_API_AUTH_OTP, { otp }));
          loggedInByOtp = isLoggedIn;
        } catch (err) {
          console.error(err);
        }
      }

      if (!loggedInByOtp) {
        ({
          data: { isLoggedIn, token },
        } = await axios.get(INTERNAL_API_SESSION));
      }

      if (!isLoggedIn && PROTECTED.includes(router.route)) {
        await router.push(SIGN_IN);
      } else if (isLoggedIn && GUEST_ONLY_ROUTES.includes(router.route)) {
        await router.push(PANEL_DASHBOARD);
      }

      setLoading(false);

      dispatch(loadSession({ isLoggedIn, token }));

      // tracking
      if (mid) {
        axios.get(API_MAIL_CLICK.replace('{mid}', mid)).catch(console.error);
      }
      FacebookPixel.init(fbPixelId, null, { debug: fbPixelDebug });
      FacebookPixel.pageView();

      // GTM Google Tag Manager
      TagManager.initialize({ gtmId });

      // Google Analytics
      ReactGA.initialize(gaId, { debug: gaDebug });
      ReactGA.pageview(router.asPath);

      ReactGA4.initialize(ga4);
      ReactGA4.send('pageview');

      tawkTo(tawkToPropertyId, tawkToKey);

      router.events.on('routeChangeComplete', () => {
        FacebookPixel.pageView();
        ReactGA.pageview(router.asPath);
      });
    }
  }, [router.isReady]);

  return useLayout(
    <>
      {loading && <LoadingScreen />}
      <Component {...pageProps} />
    </>
  );
};

export default wrapper.withRedux(appWithTranslation(TrafficBoosterApp));
