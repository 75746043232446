let pixelInstance = null;

const providePixel = async () => {
  if (pixelInstance) {
    return pixelInstance;
  }

  if (typeof window !== 'undefined') {
    const { default: ReactPixel } = await import('react-facebook-pixel');
    pixelInstance = ReactPixel;
  }

  return pixelInstance;
};

export default {
  init: (id, advancedMatching = null, options = null) => {
    providePixel()
      .then((pixel) => pixel.init(id, advancedMatching, options))
      .catch(console.error);
  },
  pageView: () => {
    providePixel()
      .then((pixel) => pixel.pageView())
      .catch(console.error);
  },
  track: (event, data) => {
    providePixel()
      .then((pixel) => pixel.track(event, data))
      .catch(console.error);
  },
  trackCustom: (event, data) => {
    providePixel()
      .then((pixel) => pixel.trackCustom(event, data))
      .catch(console.error);
  },
  URL_COMPLETE: 'UrlComplete', // {value: "url"}
  COMPLETE_REGISTRATION: 'CompleteRegistration', // {content_name, currency, status, value}
  LEAD: 'Lead', // {content_category, content_name, currency, value}
  TRAFFIC_APPROVE: 'TrafficApprove', // {}
  INITIATE_CHECKOUT: 'InitiateCheckout', // {content_category, content_ids, contents, currency, num_items, value}
  SUBSCRIBE: 'Subscribe', // {currency, predicted_ltv, value}
};
