export const HOME = '/';
export const SIGN_UP = '/sign-up';
export const SIGN_IN = '/sign-in';
export const REMIND_PASSWORD = '/remind-password';
export const AUTOLOGIN = '/autologin/{token}';
export const PRIVACY_POLICY = '/privacy-policy';
export const EULA = '/eula';
export const REFUND_POLICY = '/refund-policy';
export const LOGOUT = '/logout';

export const BLOG = '/blog';
export const BLOG_HOW_TO_IMPROVE_SEO_RANK = '/blog/how-to-improve-seo-rank';

export const PANEL_DASHBOARD = '/panel';
export const PANEL_PROJECTS = '/panel/projects';
export const PANEL_PROJECT_EDIT = '/panel/projects/{id}';
export const PANEL_PROJECT_CREATE = '/panel/projects/new';
export const PANEL_AFFILIATE = '/panel/affiliate';
export const PANEL_BILLING = '/panel/billing';
export const ACTIVE_PACKAGE = '/panel/billing/plan';
export const PANEL_BILLING_CANCEL = '/panel/billing/cancel';
export const PANEL_BILLING_PENDING = '/panel/billing/pending';
export const PANEL_HELP = '/panel/help';
export const PANEL_PROFILE = '/panel/profile';

export const ADMIN_DASHBOARD = '/admin';
export const ADMIN_PACKAGES = '/admin/packages';
export const ADMIN_PACKAGE_EDIT = '/admin/packages/{id}';
export const ADMIN_PACKAGE_VIEW = '/admin/packages/view/{id}';
export const ADMIN_PACKAGE_CREATE = '/admin/packages/create';
export const ADMIN_USERS = '/admin/users';
export const ADMIN_USER_VIEW = '/admin/users/{id}';
export const ADMIN_USER_ASSIGN_FEATURE = '/admin/users/{id}/feature';
export const ADMIN_USER_EDIT_FEATURE = '/admin/users/{id}/feature/{userFeatureId}';
export const ADMIN_FEATURES = '/admin/features';
export const ADMIN_FEATURE = '/admin/features/{id}';
export const ADMIN_FEATURE_CREATE = '/admin/features/create';

export const INTERNAL_API_AUTH = '/api/auth';
export const INTERNAL_API_AUTH_OTP = '/api/auth/otp';
export const INTERNAL_API_SESSION = '/api/session';

export const API_LOGIN = '/api/login';
export const API_AUTH_OTP = '/api/auth/otp/{token}';
export const API_ME = '/api/me';
export const API_ME_FEATURE = '/api/me/feature/{feature}';
export const API_AFFILIATE_BALANCE = '/api/affiliate/balance';
export const API_AFFILIATE_TRANSACTION = '/api/affiliate/transaction';
export const API_AFFILIATE_USER = '/api/affiliate/user';
export const API_DEMO_CHECK_URL = '/api/demo/check-url';
export const API_DEMO_REGISTER = '/api/demo/register';
export const API_FORGOT_PASSWORD = '/api/forgot-password';
export const API_VERIFY_EMAIL = '/api/verify-email/{token}';
export const API_RESET_PASSWORD = '/api/reset-password/{token}';
export const API_UNSUBSCRIBE = '/api/unsubscribe';
export const API_PROJECTS = '/api/projects';
export const API_PROJECT = '/api/projects/{id}';
export const API_PROJECT_STATUS = '/api/projects/{id}/status';
export const API_PROJECT_GEO = '/api/projects/{id}/geo';
export const API_PROJECT_TRAFFIC_STATUS = '/api/projects/traffic/status';
export const API_DASHBOARD = '/api/dashboard';
export const API_ACTIVE_PACKAGE = '/api/active-package';
export const API_PACKAGES = '/api/packages';
export const API_PACKAGE = '/api/packages/{id}';
export const API_PACKAGES_FOR_MAIN_PAGE = '/api/packages/for_main_page';
export const API_PACKAGES_ACTIVATE_STARTER = '/api/packages/activate_starter';
export const API_PAYMENT_METHODS = '/api/payment/methods';
export const API_PAYMENT_METHOD_LINK = '/api/payment/{method}/create_order/{packageId}';
export const API_PAYMENT_PAYPAL_CONFIRM_AGREEMENT = '/api/payment/paypal/confirm_agreement/{token}';
export const API_PAYMENT_PAYPAL_CAPTURE = '/api/payment/paypal/capture/{token}';
export const API_PAYMENT_CANCEL_ORDER = '/api/payment/cancel/order/{orderId}';
export const API_PAYMENT_STATUS_ORDER = '/api/payment/status/order/{orderId}';
export const API_GEO_COUNTRY = '/api/geo/country';
export const API_GEO_STATES = '/api/geo/country/{countryCode}/state';
export const API_GEO_CITIES = '/api/geo/country/{countryCode}/city';
export const API_METRICS_CLICK = '/api/metrics/click/{element}';
export const API_MAIL_CLICK = '/api/mail/click/{mid}';
export const API_USERS = '/api/users';
export const API_USER = '/api/users/{id}';
export const API_USER_FEATURES = '/api/user_features';
export const API_USER_FEATURE = '/api/user_features/{id}';
export const API_FEATURES = '/api/features';
export const API_FEATURE = '/api/features/{id}';
export const API_ORDERS = '/api/orders';

export const API_ADMIN_ACTIVE_PACKAGE_OF_USER = '/api/admin/active-package/{id}';

// list of protected routes
export const PROTECTED = [
  PANEL_DASHBOARD,
  PANEL_PROJECTS,
  PANEL_PROJECT_EDIT,
  PANEL_PROJECT_CREATE,
  PANEL_BILLING,
  PANEL_BILLING_CANCEL,
  PANEL_BILLING_PENDING,
  PANEL_HELP,
  PANEL_PROFILE,
];

// list of guest only routes
export const GUEST_ONLY_ROUTES = [SIGN_UP, SIGN_IN, REMIND_PASSWORD];
