//user
export const LOAD_SESSION = 'LOAD_SESSION';
export const LOAD_USER = 'LOAD_USER';
export const LOAD_FEATURES = 'LOAD_FEATURES';
export const PUSH_NOTIFIER = 'PUSH_NOTIFIER';
export const REMOVE_NOTIFIER = 'REMOVE_NOTIFIER';

//main app
export const LOAD_APP_STATE = 'LOAD_APP_STATE';
export const LOAD_PACKAGE_INFO = 'LOAD_PACKAGE_INFO';
export const LOAD_PROJECTS = 'LOAD_PROJECTS';
export const LOAD_AFFILIATE_BALANCE = 'LOAD_AFFILIATE_BALANCE';
export const LOAD_DASHBOARD_METRICS = 'LOAD_METRICS';
export const TICK_MINUTE = 'TICK_MINUTE';

//dashboard
export const PUSH_VISIT = 'PUSH_VISIT';
export const INCREMENT_VISITS = 'INCREMENT_VISITS';
export const INCREMENT_VISITORS = 'INCREMENT_VISITORS';

//project
export const INCREMENT_PROJECT_VISITS = 'INCREMENT_PROJECT_VISITS';
export const INCREMENT_PROJECT_VISITORS = 'INCREMENT_PROJECT_VISITORS';
export const LOAD_PROJECT_PANEL = 'LOAD_PROJECT_PANEL';
export const UPDATE_PROJECT_STATUS = 'UPDATE_PROJECT_STATUS';
export const LOAD_TRAFFIC_STATUS = 'LOAD_TRAFFIC_STATUS';

//billing
export const LOAD_PACKAGES = 'LOAD_PACKAGES';
export const LOAD_PAYMENT_METHODS = 'LOAD_PAYMENT_METHODS';
