import {
  INCREMENT_PROJECT_VISITORS,
  INCREMENT_PROJECT_VISITS,
  INCREMENT_VISITORS,
  INCREMENT_VISITS,
  LOAD_AFFILIATE_BALANCE,
  LOAD_APP_STATE,
  LOAD_PROJECTS,
  LOAD_PACKAGE_INFO,
  LOAD_PACKAGES,
  LOAD_PAYMENT_METHODS,
  LOAD_PROJECT_PANEL,
  PUSH_VISIT,
  TICK_MINUTE,
  UPDATE_PROJECT_STATUS,
  LOAD_TRAFFIC_STATUS,
  LOAD_DASHBOARD_METRICS,
} from 'src/const/actionTypes';

import _ from 'lodash';

const app = (
  state = {
    recentVisits: [],
    counters: { visits: 0, visitors: 0 },
    chart: {},
    packageInfo: {},
    packages: [],
    paymentMethods: [],
    projects: {},
    projectsTotal: 0,
    projectsLoaded: false,
    projectCharts: {},
    projectCounters: {},
    trafficStatus: [],
    affiliateBalances: [],
    ticker: {
      minute: null,
    },
  },
  action
) => {
  switch (action.type) {
    case LOAD_APP_STATE:
      return {
        ...state,
      };
    case LOAD_PROJECTS:
      return {
        ...state,
        projects: action.projects || state.projects,
        projectsTotal: action.projectsTotal || state.projectsTotal,
        projectsLoaded: action.projectsLoaded || state.projectsLoaded,
      };
    case LOAD_DASHBOARD_METRICS:
      return {
        ...state,
        counters: action.counters || state.counters,
        chart: action.chart || state.chart,
        recentVisits: action.recentVisits || state.recentVisits,
      };
    case LOAD_PACKAGE_INFO:
      return {
        ...state,
        packageInfo: action.packageInfo || state.packageInfo,
      };
    case LOAD_PACKAGES:
      return {
        ...state,
        packages: action.packages || state.packages,
      };
    case LOAD_TRAFFIC_STATUS:
      return {
        ...state,
        trafficStatus: action.trafficStatus || state.trafficStatus,
      };
    case LOAD_AFFILIATE_BALANCE:
      return {
        ...state,
        affiliateBalances: action.affiliateBalances || state.affiliateBalances,
      };
    case LOAD_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.paymentMethods || state.paymentMethods,
      };
    case PUSH_VISIT:
      return {
        ...state,
        recentVisits: [action.visit, ...state.recentVisits.slice(0, 5)],
      };
    case INCREMENT_VISITS:
      return {
        ...state,
        counters: { ...state.counters, visits: state.counters.visits + 1 },
      };
    case INCREMENT_VISITORS:
      return {
        ...state,
        counters: { ...state.counters, visitors: state.counters.visitors + 1 },
      };
    case LOAD_PROJECT_PANEL:
      return {
        ...state,
        projectCharts: {
          ...state.projectCharts,
          [action.projectId]: action.chart,
        },
        projectCounters: {
          ...state.projectCounters,
          [action.projectId]: action.counters,
        },
      };
    case INCREMENT_PROJECT_VISITS:
      if (!state.projectCounters[action.projectId]) {
        return state;
      }

      return {
        ...state,
        projectCounters: {
          ...state.projectCounters,
          [action.projectId]: {
            ...(state.projectCounters[action.projectId] || {}),
            visits: state.projectCounters[action.projectId].visits + 1,
          },
        },
      };
    case INCREMENT_PROJECT_VISITORS:
      if (!state.projectCounters[action.projectId]) {
        return state;
      }

      return {
        ...state,
        projectCounters: {
          ...state.projectCounters,
          [action.projectId]: {
            ...(state.projectCounters[action.projectId] || {}),
            visitors: state.projectCounters[action.projectId].visitors + 1,
          },
        },
      };
    case UPDATE_PROJECT_STATUS:
      if (_.isEmpty(state.projects)) {
        return state;
      }

      return {
        ...state,
        projects: {
          ...state.projects,
          [action.projectId]: {
            ...(state.projects[action.projectId] || {}),
            status: action.status,
            updatedAt: new Date().toISOString(),
          },
        },
      };
    case TICK_MINUTE:
      return {
        ...state,
        ticker: {
          ...state.ticker,
          minute: action.minute,
        },
      };
    default:
      return state;
  }
};

export default app;
